import React from 'react';

import { InvestmentPage } from '@kali/theme/lib/pages/investment/page';

import Layout from '../components/layout';

export default function Investment() {
  return (
    <Layout title="Investment" useLayoutView={false}>
      <InvestmentPage />
    </Layout>
  );
}
